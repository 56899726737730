<template>
    <div class="form-group">
<!--        <input :type="type ? type : 'text'"-->
<!--               class="form-control"-->
<!--               :placeholder="label"-->
<!--               @input="handleInput"-->
<!--               :value="value"/>-->
        <the-mask mask="+7(###)#######"
                  class="form-control"
                  :placeholder="label"
                  :value="value"
                  @input="handleInput"
                  :masked="true"/>
        <label class="form-label floating-label" :data-content="label">
            <span class="hidden--visually">{{ label }}</span>
        </label>
    </div>
<!--    <div class="form-group">-->
<!--        <label class="form-label">{{ $t(label) }}</label>-->
<!--        <the-mask mask="+7(###)#######" class="form-control" :value="value" @input="handleInput" :masked="true"/>-->
<!--    </div>-->
</template>

<script>
    import { TheMask } from 'vue-the-mask'

    export default {
        components: {
            TheMask
        },
        data() {
            return {
                content: this.value
            }
        },
        props: {
            label: {},
            value: {},
            type: {}
        },
        mounted() {
        },
        methods: {
            handleInput(e) {
                this.$emit('input', e)
            }
        }
    }
</script>

<style scoped lang="scss">

</style>